$pact-red: #df2706;

$theme-1-global-bg: rgba(39, 39, 39, .9);
$theme-1-global-font: #fff;
$theme-1-global-border-color: lighten( $theme-1-global-bg, 5% );

// Nav
$theme-1-nav-bg: rgba(29, 29, 29, 1);
$theme-1-nav-font: darken($theme-1-global-font, 20%);
$theme-1-nav-border: lighten($theme-1-nav-bg, 5%);
$theme-1-nav-brand-bg: $theme-1-nav-bg;
$theme-1-nav-brand-font: $theme-1-global-font;

// Sidebar
$theme-1-sidebar-bg: lighten( $theme-1-nav-bg, 5% );
$theme-1-sidebar-font: darken($theme-1-global-font, 20%);
$theme-1-sidebar-border: lighten($theme-1-sidebar-bg, 10%);
$theme-1-sidebar-li-hover-bg: darken( $theme-1-sidebar-bg, 15% );
$theme-1-sidebar-li-hover-font: lighten($theme-1-sidebar-font, 20%);

// Page header
$theme-1-page-header-bg: rgba(39, 39, 39, .95);
$theme-1-page-header-font: $theme-1-global-font;
$theme-1-page-border-bottom: lighten($theme-1-global-border-color, 5%);

// Box
$theme-1-box-bg: rgba(39, 39, 39, .95);
$theme-1-box-font: $theme-1-global-font;
$theme-1-box-border: lighten($theme-1-box-bg, 20%);
$theme-1-box-border-top: lighten($theme-1-box-bg, 20%);
$theme-1-box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
$theme-1-box-footer-bg: transparent;
$theme-1-small-box-bg: $theme-1-box-bg;
$theme-1-small-box-font: darken($theme-1-box-font, 20%);
$theme-1-small-box-font-hover: darken($theme-1-box-font, 10%);
$theme-1-small-box-footer-bg: darken($theme-1-box-bg, 5%);
$theme-1-small-box-footer-bg-hover: darken($theme-1-box-bg, 2.5%);
$theme-1-small-box-footer-font: darken($theme-1-box-font, 7%);
$theme-1-small-box-footer-font-hover: darken($theme-1-box-font, 5%);

// Hr
$theme-1-hr: lighten($theme-1-global-bg, 20%);

// Btn
$theme-1-btn-default-bg: rgba(0, 0, 0, .35);
$theme-1-btn-default-font: $theme-1-global-font;
$theme-1-btn-default-bg-hover: rgba(0, 0, 0, .425);
$theme-1-btn-default-bg-active: rgba(0, 0, 0, .512);
$theme-1-btn-default-border: lighten($theme-1-btn-default-bg, 10%);
$theme-1-btn-default-disabled-bg: lighten($theme-1-btn-default-bg, 20%);
$theme-1-btn-default-disabled-border: lighten($theme-1-btn-default-bg, 10%);
$theme-1-btn-default-disabled-font: darken($theme-1-btn-default-font, 20%);;





// Global
$theme-2-global-bg: rgba(255, 255, 255, .95);
$theme-2-global-font: #444;
$theme-2-global-border-color: darken($theme-2-global-bg, 5%);

// Nav
$theme-2-nav-bg: #fff;
$theme-2-nav-font: $theme-2-global-font;
$theme-2-nav-border: darken($theme-2-nav-bg, 5%);
$theme-2-nav-brand-bg: $theme-2-nav-bg;
$theme-2-nav-brand-font: $theme-2-global-font;

// Sidebar
// $theme-2-sidebar-bg: rgba(255, 255, 255, .95);
// $theme-2-sidebar-font: lighten($theme-2-global-font, 20%);
// $theme-2-sidebar-border: darken($theme-2-sidebar-bg, 10%);
// $theme-2-sidebar-li-hover-bg: lighten( $theme-2-sidebar-bg, 15% );
// $theme-2-sidebar-li-hover-font: darken($theme-2-sidebar-font, 5%);
$theme-2-sidebar-bg: #333333;
$theme-2-sidebar-font: #fff;
$theme-2-sidebar-border: lighten($theme-2-sidebar-bg, 10%);
$theme-2-sidebar-li-hover-bg: lighten( $theme-2-sidebar-bg, 15% );
$theme-2-sidebar-li-hover-font: darken($theme-2-sidebar-font, 5%);

// Page header
$theme-2-page-header-bg: #fff;
$theme-2-page-header-font: $theme-2-global-font;
$theme-2-page-border-bottom: darken($theme-2-global-border-color, 10%);

// Box
$theme-2-box-bg: rgba(255, 255, 255, .97);
$theme-2-box-font: $theme-2-global-font;
$theme-2-box-border: darken($theme-2-box-bg, 20%);
$theme-2-box-border-top: darken($theme-2-box-bg, 20%);
$theme-2-box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
$theme-2-small-box-bg: $theme-2-box-bg;
$theme-2-box-footer-bg: darken($theme-2-box-bg, 3%);
$theme-2-small-box-font: lighten($theme-2-box-font, 20%);
$theme-2-small-box-font-hover: lighten($theme-2-box-font, 10%);
$theme-2-small-box-footer-bg: darken($theme-2-box-bg, 5%);
$theme-2-small-box-footer-bg-hover: darken($theme-2-box-bg, 2.5%);
$theme-2-small-box-footer-font: lighten($theme-2-box-font, 7%);
$theme-2-small-box-footer-font-hover: lighten($theme-2-box-font, 5%);

// Hr
$theme-2-hr: darken($theme-2-global-bg, 10%);

// Btn
$theme-2-btn-default-bg: darken($theme-2-global-bg, 5%);
$theme-2-btn-default-font: $theme-2-global-font;
$theme-2-btn-default-bg-hover: darken($theme-2-global-bg, 10%);
$theme-2-btn-default-bg-active: darken($theme-2-global-bg, 15%);
$theme-2-btn-default-border: darken($theme-2-btn-default-bg, 10%);
$theme-2-btn-default-disabled-bg: darken($theme-2-btn-default-bg, 20%);
$theme-2-btn-default-disabled-border: darken($theme-2-btn-default-bg, 10%);
$theme-2-btn-default-disabled-font: lighten($theme-2-btn-default-font, 20%);

$theme-2-btn-primary-font: #fff;