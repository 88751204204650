@import "color-theme";

$global-bg: $theme-2-global-bg;
$global-font: $theme-2-global-font;
$global-border-color: $theme-2-global-border-color;
$nav-bg: $theme-2-nav-bg;
$nav-font: $theme-2-nav-font;
$nav-border: $theme-2-nav-border;
$nav-brand-bg: $theme-2-nav-brand-bg;
$nav-brand-font: $theme-2-nav-brand-font;
$sidebar-bg: $theme-2-sidebar-bg;
$sidebar-font: $theme-2-sidebar-font;
$sidebar-border: $theme-2-sidebar-border;
$sidebar-li-hover-bg: $theme-2-sidebar-li-hover-bg;
$sidebar-li-hover-font: $theme-2-sidebar-li-hover-font;
$page-header-bg: $theme-2-page-header-bg;
$page-header-font: $theme-2-page-header-font;
$page-header-border-bottom: $theme-2-page-border-bottom;

$box-bg: $theme-2-box-bg;
$box-font: $theme-2-box-font;
$box-border: $theme-2-box-border;
$box-border-top: $theme-2-box-border-top;
$box-shadow: $theme-2-box-shadow;
$box-footer-bg: $theme-2-box-footer-bg;
$small-box-bg: $theme-2-small-box-bg;
$small-box-font: $theme-2-small-box-font;
$small-box-font-hover: $theme-2-small-box-font-hover;
$small-box-footer-bg: $theme-2-small-box-footer-bg;
$small-box-footer-bg-hover: $theme-2-small-box-footer-bg-hover;
$small-box-footer-font: $theme-2-small-box-footer-font;
$small-box-footer-font-hover: $theme-2-small-box-footer-font-hover;
$hr: $theme-2-hr;

$btn-default-bg: $theme-2-btn-default-bg;
$btn-default-font: $theme-2-btn-default-font;
$btn-default-bg-hover: $theme-2-btn-default-bg-hover;
$btn-default-bg-active: $theme-2-btn-default-bg-active;
$btn-default-border: $theme-2-btn-default-border;
$btn-default-disabled-bg: $theme-2-btn-default-disabled-bg;
$btn-default-disabled-border: $theme-2-btn-default-disabled-border;
$btn-default-disabled-font: $theme-2-btn-default-disabled-font;

$btn-primary-font: $theme-2-btn-primary-font;

$light-pact-red: lighten($pact-red, 17%);

@function lightenOrDarken($color, $amount) {
  @if (lightness($color) > 50) {
    @return darken($color, $amount);
  } @else {
    @return lighten($color, $amount);
  }
}

/*
 * Theme
 * -----------
 */

body.skin-trans-custom {
  /*background-image: url(../images/blurred-background-7.jpg)!important;*/
	/*background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAADCAYAAABfwxXFAAAAYklEQVQYVwFXAKj/AROFV/8UKCAA+PXoAAXt+QDn2PAAH/MRAA/oBQABHmtR/wISEAABCgEAIRIQABAMEwDnwuMA8cndAAGXqbT/tLO0AN705wAVKB4AHxcdAP/g8gAcCxUAY9se/IJzZGkAAAAASUVORK5CYII=");*/
  /*background-image: url(http://pact-laravel-5-2.dev/api/v1/regions/1/get_profile_image?last_modified=2017-07-18%2015:12:11)!important;*/
  background-color: lighten(lightgrey, 10%);
  -webkit-overflow-scrolling: touch;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  color: $global-font;
}

body.skin-trans-custom:not(.signup)::before {
  content: '\A';
  position: fixed;
  top:0; left:0; bottom:0px; right:0px;
  background: transparentize($global-bg, .3);
  opacity: 1;
  transition: all 1s;
  -webkit-transition: all 1s;
}


h3 small {
  color: $global-font;
}

.skin-trans-custom a {
  color: $global-font;
  cursor: pointer;
}
.skin-trans-custom hr {
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: $hr;
  // border-top: 1px solid rgba(255, 255, 255, 0.35)!important;
}

/* skin-trans navbar */
.skin-trans-custom .main-header {
  -webkit-box-shadow: $box-shadow;
  box-shadow: $box-shadow;
}
.skin-trans-custom .main-header .navbar-toggle {
  color: $global-font;
}
.skin-trans-custom .main-header .navbar-brand {
  color: $nav-brand-font;
  background: $nav-brand-bg;
}
.skin-trans-custom .main-header > .navbar {
  background-color: $nav-bg;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: $nav-border;
  // border-bottom-color: lighten($pact-red, 10%);
}
.skin-trans-custom .main-header > .navbar .nav > li > a {
  color: $nav-font;
}
.skin-trans-custom .main-header > .navbar .nav > li > a:hover,
.skin-trans-custom .main-header > .navbar .nav > li > a:active,
.skin-trans-custom .main-header > .navbar .nav > li > a:focus,
.skin-trans-custom .main-header > .navbar .nav .open > a,
.skin-trans-custom .main-header > .navbar .nav .open > a:hover,
.skin-trans-custom .main-header > .navbar .nav .open > a:focus,
.skin-trans-custom .main-header > .navbar .nav > .active > a {
  background: darken( $nav-bg, 5% );
  color: $nav-font;
}
.skin-trans-custom .main-header > .navbar .sidebar-toggle {
  color: $pact-red;
}
.skin-trans-custom .main-header > .navbar .sidebar-toggle:hover {
  // background: darken( $nav-bg, 5% );
  background: $global-bg;
}
.skin-trans-custom .main-header > .navbar > .sidebar-toggle {
  color: $nav-font;
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: $nav-border;
}
.skin-trans-custom .main-header > .navbar .navbar-nav > li > a {
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: $nav-border;
}
.skin-trans-custom .main-header > .navbar .navbar-custom-menu .navbar-nav > li > a,
.skin-trans-custom .main-header > .navbar .navbar-right > li > a {
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: $nav-border;
  border-right-width: 0;
}
.skin-trans-custom .main-header > .logo {
  cursor: default;
  background-color: $nav-bg;
  color: $pact-red;
  border-bottom-width: 0;
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: $nav-border;
}

@media (max-width: 767px) {
  .skin-trans-custom .main-header > .logo {
    background-color: $nav-brand-bg;
    color: $pact-red;
    border-bottom: 0 solid transparent;
    border-right: none;
    display: none;
  }
  .skin-trans-custom .wrapper,
  .skin-trans-custom .main-sidebar,
  .skin-trans-custom .left-side {
    background-color: transparent;
  }
  .skin-trans-custom .sidebar {
    background-color: $sidebar-bg;
  }
}
.skin-trans-custom .main-header li.user-header {
  background-color: $sidebar-bg;
}
.skin-trans-custom .content-header {
  background: transparent;
  box-shadow: none;
}
/*.skin-trans-custom .wrapper,*/
.skin-trans-custom .main-sidebar,
.skin-trans-custom .left-side {
  background-color: $sidebar-bg;
  border-right-width: 1px;
  border-right-style: solid;
  // border-right-color: $sidebar-border;

  // border-image: linear-gradient(to bottom, $light-pact-red 25%,#ffcd02 25%, #ffcd02 50%, #e84f47 50%, #e84f47 75%, #65c1ac 75%) 2;
  border-image: linear-gradient(to bottom, $light-pact-red 58px, $sidebar-border 58px, $sidebar-border 100% ) 1;

  // border-image:   linear-gradient(to bottom, grey 25%, yellow 25%, yellow 50%,red 50%, red 75%, teal 75%) 5;
  // background: linear-gradient(to right, #bcbcbc 25%,#ffcd02 25%, #ffcd02 50%, #e84f47 50%, #e84f47 75%, #65c1ac 75%);

  // border-top-width: 7px;
  // border-top-style: solid;
  // border-top-color: $light-pact-red;;
  
}
.skin-trans-custom .content-wrapper,
.skin-trans-custom .main-footer {
  /*Commented this out as there was a gap between the sidebar and the content header*/
  border-left: none;
}
.skin-trans-custom .user-panel > .info,
.skin-trans-custom .user-panel > .info > a {
  color: $sidebar-font;
}
.skin-trans-custom .sidebar-menu > li {
  -webkit-transition: border-left-color 0.3s ease;
  -o-transition: border-left-color 0.3s ease;
  transition: border-left-color 0.3s ease;
}
.skin-trans-custom .sidebar-menu > li.header {
  color: $sidebar-font;
  background: darken( $sidebar-bg, 10% );
}
.skin-trans-custom .sidebar-menu > li > a {
  border-left: 3px solid transparent;
  font-weight: 600;
  color: $sidebar-font;
}
.skin-trans-custom .sidebar-menu > li:hover > a {
  // color: lighten($sidebar-font, 20%);
  // background: darken( $sidebar-bg, 15% );
  color: $sidebar-li-hover-font;
  background: $sidebar-li-hover-bg;
}
.skin-trans-custom .sidebar-menu > li.active > a {
  // color: lighten($sidebar-font, 20%);
  // background: darken( $sidebar-bg, 12% );
  color: $pact-red;
  background: $sidebar-li-hover-bg;
  box-shadow: $box-shadow;
}

.skin-trans-custom.sidebar-mini.sidebar-collapse .sidebar-menu > li:hover > a {
  // background: darken( $sidebar-bg, 30% );
  background: $sidebar-li-hover-bg;
  border: 1px solid $sidebar-border;
}

.skin-trans-custom.sidebar-mini.sidebar-collapse .sidebar-menu > li.active > a {
  // background: darken( $sidebar-bg, 30% );
  background: $sidebar-li-hover-bg;
}

.skin-trans-custom .sidebar-menu > li.active > a {
  border-left-color: $sidebar-border;
}
.skin-trans-custom .sidebar-menu > li.active > a {
  font-weight: 600;
}


@media (max-width: 767px) {
  .skin-trans-custom .content-wrapper,
  .skin-trans-custom .main-footer {
    border-left-width: 0;
  }

  .skin-trans-custom .wrapper,
  .skin-trans-custom .main-sidebar,
  .skin-trans-custom .left-side {
    background-color: transparent;
  }
}

/*Widget-boxes*/
.box.box-widget .widget-user-header {
  // background-color: rgba(0, 0, 0, 0.1);
}

.box.box-widget .box-footer {
  background-color: rgba(0, 0, 0, 0);
  color: $box-font;
}

.box.box-widget .box-footer a {
  color: $box-font;
}

.skin-trans-custom table th, .skin-trans-custom table td {
  border-color: rgba(255, 255, 255, 0.1)!important;
}


// BTN
.btn-default {
  background-color: $btn-default-bg;
  color: $btn-default-font!important;
  border-color: $btn-default-border;
}
.btn-default:hover,
.btn-default:active,
.btn-default:focus,
.btn-default.hover {
  background-color: $btn-default-bg-hover!important;
  color: $btn-default-font!important;
}

.btn-default:active {
  background-color: $btn-default-bg-active!important;
}

.btn-default.disabled, .btn-default[disabled], fieldset[disabled] .btn-default, .btn-default.disabled:hover, .btn-default[disabled]:hover, fieldset[disabled] .btn-default:hover, .btn-default.disabled:focus, .btn-default[disabled]:focus, fieldset[disabled] .btn-default:focus, .btn-default.disabled:active, .btn-default[disabled]:active, fieldset[disabled] .btn-default:active, .btn-default.disabled.active, .btn-default[disabled].active, fieldset[disabled] .btn-default.active {
    background-color: $btn-default-disabled-bg;
    border-color: $btn-default-disabled-border;
    color: $btn-default-disabled-font;
}


.btn-pact {
  background-color: $pact-red!important;
  color: #fff!important;
  border-color: darken($pact-red, 10%);
}
.btn-pact:hover,
.btn-pact:active,
.btn-pact:focus,
.btn-pact.hover {
  background-color: darken($pact-red, 5%)!important;
  color: #fff!important;
}

.skin-trans-custom a.btn {
  color: $btn-primary-font;
}

// .btn-pact:active {
//   background-color: $btn-pact-bg-active!important;
// }

// .btn-pact.disabled, .btn-pact[disabled], fieldset[disabled] .btn-pact, .btn-pact.disabled:hover, .btn-pact[disabled]:hover, fieldset[disabled] .btn-pact:hover, .btn-pact.disabled:focus, .btn-pact[disabled]:focus, fieldset[disabled] .btn-pact:focus, .btn-pact.disabled:active, .btn-pact[disabled]:active, fieldset[disabled] .btn-pact:active, .btn-pact.disabled.active, .btn-pact[disabled].active, fieldset[disabled] .btn-pact.active {
//     background-color: $btn-pact-disabled-bg;
//     border-color: $btn-pact-disabled-border;
//     color: $btn-pact-disabled-font;
// }


.skin-trans-custom .bg-gray {
  background-color: #d2d6de !important;
  // background-color: rgba(210, 214, 222, .4)!important;
}
.skin-trans-custom .bg-gray-light {
  background-color: #f7f7f7;
  // background-color: rgba(247, 247, 247, .4)!important;
}
.skin-trans-custom .bg-black {
  background-color: #111111 !important;
  // background-color: rgba(17, 17, 17, .4)!important;
}
.skin-trans-custom .bg-red {
  background-color: #dd4b39 !important;
  // background-color: rgba(221, 75, 57, .4)!important;
}
.skin-trans-custom .bg-yellow {
  background-color: #f39c12 !important;
  // background-color: rgba(243, 156, 18, .4)!important;
}
.skin-trans-custom .bg-aqua {
  background-color: #00c0ef !important;
  // background-color: rgba(0, 192, 239, .4)!important;
}
.skin-trans-custom .bg-blue {
  background-color: #0073b7 !important;
  // background-color: rgba(0, 115, 183, .4)!important;
}
.skin-trans-custom .bg-light-blue {
  background-color: #3c8dbc !important;
  // background-color: rgba(60, 141, 188, .4)!important;
}
.skin-trans-custom .bg-green {
  background-color: #00a65a !important;
  // background-color: rgba(0, 166, 90, .4)!important;
}
.skin-trans-custom .bg-navy {
  background-color: #001f3f !important;
  // background-color: rgba(0, 31, 63, .4)!important;
}
.skin-trans-custom .bg-teal {
  background-color: #39cccc !important;
  // background-color: rgba(57, 204, 204, .4)!important;
}
.skin-trans-custom .bg-olive {
  background-color: #3d9970 !important;
  // background-color: rgba(61, 153, 112, .4)!important;
}
.skin-trans-custom .bg-lime {
  background-color: #01ff70 !important;
  // background-color: rgba(1, 255, 112, .4)!important;
}
.skin-trans-custom .bg-orange {
  background-color: #ff851b !important;
  // background-color: rgba(255, 133, 27, .4)!important;
}
.skin-trans-custom .bg-fuchsia {
  background-color: #f012be !important;
  // background-color: rgba(240, 18, 190, .4)!important;
}
.skin-trans-custom .bg-purple {
  background-color: #605ca8 !important;
  // background-color: rgba(96, 92, 168, .4)!important;
}
.skin-trans-custom .bg-maroon {
  background-color: #d81b60 !important;
  // background-color: rgba(216, 27, 96, .4)!important;
}

input, textarea, select, .form-control {
  background-color: $global-bg!important;
  color: $global-font!important;
  /*border-color: rgba(255, 255, 255, 0.2)!important;*/
  border-color: rgba(0, 0, 0, 0.07);
}

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color: lightenOrDarken($global-font, 35%)!important;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color: lightenOrDarken($global-font, 35%)!important;
  opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: lightenOrDarken($global-font, 35%)!important;
  opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: lightenOrDarken($global-font, 35%)!important;
}
:placeholder-shown { /* Standard (https://drafts.csswg.org/selectors-4/#placeholder) */
  color: lightenOrDarken($global-font, 35%)!important;
}

.placeholder-text::before {
  color: lightenOrDarken($global-font, 35%)!important;
}

.input-group .input-group-addon {
  border-color: rgba(0, 0, 0, 0.07);
  background-color: rgba(0, 0, 0, 0.35);
  color: #fff;
}

.table-hover > tbody > tr:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

/*Select2 Overrides*/
.select2-container--default .select2-selection--single,
.select2-container--default .select2-selection--multiple {
  background-color: rgba(0, 0, 0, 0.2);
}

.select2-container--default .select2-selection--single .select2-selection__rendered,
.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  color: rgba(255, 255, 255, 0.8);
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  padding-left: 8px;
}

.select2-container--default .select2-results__option {
  background-color: rgba(0, 0, 0, 0.1);
  color: #fff;
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: rgba(255, 255, 255, 0.4);
  color: #000
}

.select2-results__option .select2-results__option--highlighted {
  background-color: rgba(255, 255, 255, 0.1);
  color: #000
}

.select2-dropdown {
  /*background-color: rgba(0, 0, 0, 0.7);*/
  /*background-image: url(../images/royalty-free-images-autumn-colour-backgrounds-04.jpg)!important;*/

  /*background-color: green;
  background-image: url(../images/royalty-free-images-autumn-colour-backgrounds-04.jpg);*/
}

.select2-dropdown--below {
  /*background-color: green;*/
  /*background-image: url(../images/royalty-free-images-autumn-colour-backgrounds-04.jpg);*/
  background-image: url(../images/blurred-background-7.jpg)!important;
  -webkit-overflow-scrolling: touch;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

.select2-results {
  background-color: rgba(0, 0, 0, 0.7);
}

.select2-hidden-accessible {
  display: none !important; 
  visibility: hidden !important;
}

/*SELECT " OVERRIDE FOR DISABLED"*/
.select2-container--default.select2-container--disabled .select2-selection--single,
.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: rgba(0,0,0,0.4);
  cursor: not-allowed;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b,
.select2-container--default .select2-selection--multiple .select2-selection__arrow b {
  border-color: rgba(255, 255, 255, 0.8) transparent transparent transparent;
}

.inline-header .select2.select2-container {
  vertical-align: top;
}

.inline-header > p {
  vertical-align: top;
}

/*.select2-selection.select2-selection--multiple .select2-selection__rendered .select2-search.select2-search--inline*/
.select2-selection.select2-selection--multiple .select2-selection__rendered {
  /*padding: 0;*/
}

.select2-search.select2-search--inline .select2-search__field {
  background-color: rgba(0, 0, 0, 0)!important;
  /*margin-top: 0;*/
}

.select2-selection .select2-selection__rendered .select2-selection__choice {
  /*margin-top: 0px;*/
}

.select2-container--default .select2-selection--multiple {
    border-color: rgba(210, 214, 222, 0);
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: rgba(0, 0, 0, 0.1);
  border-color: rgba(255, 255, 255, 0.2);
}


/*.select2.no-search .select2-search--dropdown {
  display: none!important;
}*/


/*====================================================*/
/*ANGULAR UI-SELECT*/
/*====================================================*/
.ui-select-bootstrap .ui-select-choices-row > span {
  color: #fff!important;
  white-space: normal!important;
}

.ui-select-bootstrap .ui-select-choices-row > span:hover {
  color: #333!important;
}

.ui-select-choices.ui-select-choices-content.ui-select-dropdown.dropdown-menu {
  background-image: url(../images/blurred-background-7.jpg)!important;
  -webkit-overflow-scrolling: touch;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  border-radius: 0;
  padding: 0;
}

.ui-select-choices .ui-select-choices-group {
  background-color: rgba(0, 0, 0, 0.7);
}

.ui-select-match.btn-default-focus {
  background-color: transparent;
  box-shadow: none;
}

.btn.btn-default.form-control.ui-select-toggle {
  border-radius: 0;
}

.ui-select-bootstrap .ui-select-choices-row.disabled > span {
  color: #333!important;
  background-color: rgba(255, 255, 255, 0.5)!important;
}

.ui-select-multiple.ui-select-bootstrap {
  padding: 4px 10px!important;
}

/*end*/

/*Pagination*/
.pagination > li > a {
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
  border-color: rgba(0, 0, 0, 0.02);
}

.pagination > .disabled > span, .pagination > .disabled > span:hover, .pagination > .disabled > span:focus, .pagination > .disabled > a, .pagination > .disabled > a:hover, .pagination > .disabled > a:focus {
  background: rgba(0, 0, 0, 0.2);
  color: rgba(255, 255, 255, 0.8); 
  border-color: rgba(0, 0, 0, 0.02)
}

.pagination > li > a:hover, .pagination > li > span:hover, .pagination > li > a:focus, .pagination > li > span:focus {
  background: rgba(0, 0, 0, 0.3);
  color: rgba(255, 255, 255, 1);
  border-color: rgba(0, 0, 0, 0.0)
}

.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
  background: rgba(0, 0, 0, 0.4);
  color: rgba(255, 255, 255, 1);
  border-color: rgba(0, 0, 0, 0.01)
}


/*List views*/
.products-list {
  margin-left: -10px;
  margin-right: -10px;
}
.product-list-in-box > .item {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.product-list-in-box > .item:first-of-type:not(.no-top-border) {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.products-list > .item {
  background: transparent;
  color: $box-font;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}

.products-list > .item:not(.no-hover):hover {
  background: rgba(0, 0, 0, 0.05);
  cursor: pointer;
}

.products-list > .item.no-hover {
  cursor: default!important;
}

.products-list .product-title, .products-list .product-description {
  color: $box-font;
}

.products-list .product-info .product-title {
  word-break: break-all;
}

// .text-muted {
//   color: $box-font;
// }


/*Tables*/
.table .table {
    background-color: transparent;
}

.table-bordered {
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.table-question-view tbody > tr > td {
  background-color: rgba(0, 0, 0, 0.2)!important;
  border-color: rgba(0, 0, 0, 0.07)!important;
}
.table-bordered > thead > tr > th, .table-bordered > tbody > tr > th, .table-bordered > tfoot > tr > th, .table-bordered > thead > tr > td, .table-bordered > tbody > tr > td, .table-bordered > tfoot > tr > td {
  border: 1px solid rgba(0, 0, 0, 0.07)!important;
}

/*Modal Windows*/
.modal-content {
  background-image: url(../images/blurred-background-7.jpg)!important;
  -webkit-overflow-scrolling: touch;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;

}

// .modal-header, .modal-body, .modal-footer {
//   background-color: rgba(0, 0, 0, 0.2);
// }

.btn-default:active, .btn-default.active, .open > .dropdown-toggle.btn-default {
  color: $btn-default-font!important;
  background-color: $btn-default-bg-active;
  border-color: $btn-default-border;
}


/*Date time picker dropdown*/
.datetimepicker span:hover {
  background: $global-bg!important;
}

.datetimepicker .minute:hover, .datetimepicker .hour:hover, .datetimepicker .day:hover, .datetimepicker .switch:hover, .datetimepicker .left:hover, .datetimepicker .right:hover {
  background: $btn-default-bg-hover!important;
  cursor: pointer;
}



// MODULES/LAYOUT/HEADER

.page-header .section-header {
  background: $page-header-bg;
  border-bottom-color: $page-header-border-bottom;
  border-top: 7px solid $light-pact-red;
}

// This is for the border at the top of the sidebar
.user-panel {
  border-top: 7px solid $light-pact-red;
}

.user-block .description {
    color: lightenOrDarken($box-font, 10%);
}

// section.sidebar {
//   border-top: 1px solid $global-border-color;
// }


/*
 * Component: Small Box
 * --------------------
 */
.small-box {
  background: $small_box-bg;
}
.small-box > .small-box-footer {
  color: $small-box-font;
  background: $small-box-bg;
}
.small-box > .small-box-footer:hover {
  color: $small-box-footer-font-hover;
  background: $small-box-footer-bg-hover;
}
.small-box p > small {
  color: $small-box-font;
}
.small-box .icon {
  color: $small-box-font;
}
.small-box:hover {
  color: $small-box-font-hover;
}

.small-box:hover .icon{
  color: $small-box-font-hover;
}
/*
 * Component: Box
 * --------------
 */
.box {
  background: $box-bg;
  border-width: 1px;
  border-style: solid;
  border-color: $box-border;
  border-top: 3px solid $box-border-top;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}
.box.box-primary {
  // border-top-color: rgba(60, 141, 188, .4);
  border-top-color: $pact-red;
}
.box.box-default {
  border-top-color: rgba(210, 214, 222, .4);
}
.box .nav-stacked > li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0;
}
.box .border-right {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.box .border-left {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}
.box.box-solid > .box-header .btn.btn-default {
  background: transparent;
}
.box.box-solid > .box-header .btn:hover,
.box.box-solid > .box-header a:hover {
  background: rgba(0, 0, 0, 0.1);
}
.box.box-solid.box-default {
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.box.box-solid.box-default > .box-header {
  color: $box-font;
  background-color: darken($box-bg, 5%);
}
.box.box-solid.box-default > .box-header a,
.box.box-solid.box-default > .box-header .btn {
  color: $box-font;
}
.box.box-solid.box-primary {
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.box.box-solid.box-primary > .box-header {
  color: $box-font;
  background: rgba(60, 141, 188, .4);
  background-color: rgba(60, 141, 188, .4);
}
.box.box-solid.box-primary > .box-header a,
.box.box-solid.box-primary > .box-header .btn {
  color: $box-font;
}
.box.box-solid[class*='bg'] > .box-header {
  color: $box-font;
}
.box .knob-label {
  // color: #333;
  color: darken($box-font, 5%);
}
.box .overlay,
.overlay-wrapper .overlay {
  background: rgba(255, 255, 255, 0.7);
}
.box .overlay > .fa,
.overlay-wrapper .overlay > .fa {
  color: #000;
}
.box .overlay.dark,
.overlay-wrapper .overlay.dark {
  background: rgba(0, 0, 0, 0.5);
}
.box-header {
  color: $box-font;
}
.box-header.with-border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.btn-box-tool {
  color: darken($box-font, 5%);
}
.open .btn-box-tool,
.btn-box-tool:hover {
  color: darken($box-font, 1%);
}
.box-footer {
  border-top: 1px solid $box-border;
  // background-color: rgba(0, 0, 0, 0.35);
  background-color: $box-footer-bg;
}
.box-comments {
  background: lighten($box-bg, 5%);
}
.box-comments .box-comment {
  border-bottom: 1px solid $box-border;
}
.box-comments .comment-text {
  color: darken($box-font, 5%);
}
.box-comments .username {
  color: darken($box-font, 3%);
}






/* NAV TABS */
.nav-tabs {
  // border-bottom-color: rgba(0, 0, 0, .07);
  border-bottom-color: $box-border;
}
.nav-tabs-custom {
  background: transparent;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  border-width: 1px;
  border-style: solid;
  border-color: $box-border;
}
.nav-tabs-custom > .nav-tabs {
  background-color: $box-bg;
}
.nav-tabs-custom > .nav-tabs > li > a {
  color: $box-font;
}
.nav-tabs-custom > .nav-tabs > li > a.text-muted {
  color: darken($box-font, 5%);
}
.nav-tabs-custom > .nav-tabs > li > a:hover {
  color: lighten($box-font, 10%);
}
.nav-tabs-custom > .nav-tabs > li.active {
  border-top-color: lighten($pact-red, 10%);
}
.nav-tabs-custom > .nav-tabs > li.active > a,
.nav-tabs-custom > .nav-tabs > li.active:hover > a {
  background: darken($box-bg, 2%);
  // color: $box-font;
}
.nav-tabs-custom > .nav-tabs > li.active > a {
  border-top-color: transparent;
  border-left-color: $box-border;
  border-right-color: $box-border;
}
.nav-tabs-custom > .nav-tabs > li:first-of-type.active > a {
  border-left-color: transparent;
}
.nav-tabs-custom > .nav-tabs.pull-right > li:first-of-type.active > a {
  border-left-color: $box-border;
  border-right-color: transparent;
}
.nav-tabs-custom > .nav-tabs > li.header {
  color: $box-font;
}
.nav-tabs-custom > .tab-content {
  background: $box-bg;
}
.nav-tabs-custom .dropdown.open > a:active,
.nav-tabs-custom .dropdown.open > a:focus {
  background: transparent;
  color: $box-font;
}

// DROPDOWNS
/*Dropdowns in general*/
.dropdown-menu {
  border-color: $btn-default-border;
  background-color: $box-bg;
}
.dropdown-menu > li > a {
  color: $btn-default-font;
}
.dropdown-menu > li > a:hover {
  background-color: $btn-default-bg-hover;
  color: $btn-default-font;
}
.dropdown-menu > .divider {
  background-color: $btn-default-border;
}

/*
  Post
*/

.post {
  border-bottom: 1px solid rgba(210, 214, 222, .4);
  color: $global-font;
}

.text-muted {
  color: desaturate($global-font, 20%)
}


// Media overlay for next buttons on media
.media-image-overlay button {
  // background-color: $global-bg;
  // border-color: $global-border-color;
  // color: $global-font;

  background-color: rgba(0, 0, 0, 0.7);
  border-color: rgba(0, 0, 0, 0.3);
  color: darken(#fff, 20%);
}




// LOGIN
/*
 * Page: Login & Register
 * ----------------------
 */
.login-logo,
.register-logo {
  font-size: 35px;
  text-align: center;
  margin-bottom: 25px;
  font-weight: 300;
  color: $box-font;
}
.login-logo a,
.register-logo a {
  color: $box-font;
}
.login-page,
.register-page {
  /*background: rgba(210, 214, 222, .4);*/
}
.login-box,
.register-box {
  width: 360px;
}
@media (max-width: 768px) {
  .login-box,
  .register-box {
    width: 90%;
    margin-top: 20px;
  }
}
.login-box-body,
.register-box-body {
  // background: rgba(0, 0, 0, 0.2);
  background: $box-bg;
  padding: 20px;
  border-top: 0;
  color: $box-font;
}
.login-box-body .form-control-feedback,
.register-box-body .form-control-feedback {
  color: $box-font;
}
.login-box-msg,
.register-box-msg {
  margin: 0;
  text-align: center;
  padding: 0 20px 20px 20px;
}

.login-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.pact-red {
  color: $pact-red;
}

.pact-red {
  background-color: $pact-red!important;
  color: #fff!important;
}



// MESSAGES
.messages .messages-main {
  border-top: 7px solid $light-pact-red;
  background-color: $box-bg;
}
.messages .conversation-list-header .message-header-font,
.messages .messages-header .message-header-font {
  color: $box-font;
}

.messages .messages-main .conversation-list-header,
.messages .messages-main .messages-header {
  border-bottom: 1px solid $box-border;
}

.messages .messages-main .conversation-list .contacts-list li:hover {
  background-color: lightenOrDarken($box-bg, 5%); 
}

.messages .messages-main .conversation-list .contacts-list li.active {
  border-left: 5px solid $light-pact-red;
}


// CONTACTS ADMINLTE
.direct-chat-contacts {
  background: $box-bg;
  color: $box-font;
}

.contacts-list-info {
  color: $box-font;
}